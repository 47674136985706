import React from "react"
import Seo from "../components/seo"
import LayoutBlog from "../layouts/blog"
import PostListCard from "../components/blog/post-list-card"

const BlogCategoryPage = ({ pageContext }) => {
  const { category } = pageContext

  const breadcrumbList = [
    { to: "/", name: "Главная" },
    { to: "/blog", name: "Статьи" },
    { to: `/blog/${category.slug}`, name: category.heading },
  ]

  const pageData = {
    showAside: true,
    hero: <h1 className="heading">{category.heading}</h1>,
    content: category.posts && category.posts.map(item => <PostListCard key={item.id} post={item} />)
  }

  return (
    <LayoutBlog breadcrumbs={breadcrumbList} page={pageData}>
      <Seo
        title={category.heading}
        description={category.heading}
        url={`/blog/${category.slug}`}
      />
    </LayoutBlog>
  )
}

export default BlogCategoryPage