import React from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import moment from "../../util/moment"
import MetaList, { MetaItem } from "../meta"
import Owner from "../owner"
import Image from "../image"

const PostListCard = ({ post }) => {
  const { owner, category, image } = post
  const postLink = `/blog/${category.slug}/${post.slug}`

  const toPost = () => {
    navigate(postLink)
  }

  return (
    <PostCard>
      <h3 className="heading">
        <Link to={postLink}>{post.heading}</Link>
      </h3>
      {image && (
        <span role="button" onClick={toPost} onKeyPress={toPost} tabIndex={0}>
          <Image type="fluid" image={image} />
        </span>
      )}
      <MetaList>
        <Owner owner={owner} small={moment(post.publishedAt).fromNow()} />
        <MetaItem className="ml-auto" icon="eye" small={post.views} />
      </MetaList>
    </PostCard>
  )
}

export default PostListCard

const PostCard = styled.article`
  margin-bottom: 2rem;

  > .heading {
    padding-left: var(--offset-one-side);
    padding-right: var(--offset-one-side);
    margin-bottom: 0.5em;
  }
  .heading a {
    color: currentColor;
    &:hover {
      text-decoration: none;
    }
  }
  > span {
    cursor: pointer;
  }
`
